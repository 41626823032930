import React, { useEffect } from 'react';
import moment from 'moment';

import s from './RainyDoload.module.scss';
import titleImg from './images/title.svg';
import versionIcon from './images/versionIcon.svg';
import wechatIcon from './images/wechatIcon.svg';
import banner1 from './images/banner1.png';
import icpLogo from './images/icpLogo.png';
import weixinGuide from './images/weixinGuide.svg';
import androidIcon from './images/androidIcon.svg';
import iosIcon from './images/iosIcon.svg';

function RainyDoload() {
  const UA = navigator.userAgent;
  const isWeixin = /MicroMessenger/gi.test(UA);
  // const isAndroid = /android|adr/gi.test(UA);
  // const isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid;

  useEffect(() => {
    document.title = 'Rainy';
  });

  if (isWeixin) {
    return (
      <div className={s.weixin}>
        <div className={s.content}>
          <img src={weixinGuide} alt="icon" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div>
        <div className={s.title}>
          <img src={titleImg} alt="" />
          <img src={versionIcon} alt="" />
        </div>
        <div className={s.desc}>放松身心，聆听四季雨水的声音</div>
        <div className={s.banner}>
          <img src={banner1} alt="" />
        </div>
        <div
          className={`${s.androidDownloadBtn} ${s.btn}`}
          onClick={() => {
            const isProd = window.location.host === 'rainy.qscare.cn';
            const appUrl = isProd
              ? 'https://cloudtrust-family-1252715994.cos.ap-nanjing.myqcloud.com/production/rainy-release.apk'
              : 'https://cloudtrust-family-1252715994.cos.ap-nanjing.myqcloud.com/test-stage/rainy-release.apk';
            window.location.replace(appUrl);
          }}
        >
          <img src={androidIcon} alt="" />
          Android版 下载
        </div>
        <div className={`${s.downloadBtn} ${s.btn}`}>
          <img src={iosIcon} alt="" /> iOS版 即将上线
        </div>
      </div>
      <div className={s.footer}>
        <div className={s.wechat}>
          <img src={wechatIcon} alt="" />
          WeChat: lzy10120308
        </div>
        <div className={s.line} />
        <div>
          <a
            href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E5%8D%97%E4%BA%AC%E5%B7%9D%E9%99%80%E5%A4%A7%E5%8C%A0%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&code=ELMJx&type=xuke"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏B2-20190842
          </a>
          &nbsp; &nbsp;&nbsp;
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
            苏 ICP 备19039170号
          </a>
        </div>
        <div className={s.icp}>
          <img src={icpLogo} alt="icpLogo" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010502010385"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏公网安备 32010502010385号
          </a>
        </div>
        <div>© 2016-{moment().year()}具光数智科技（南京）有限公司版权所有</div>
      </div>
    </div>
  );
}

export default RainyDoload;
